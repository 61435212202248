var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.reportSettings && _vm.keyword ? _c('report-page', [_c('main-title', {
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_vm.limitKwd ? _c('div', {
          staticStyle: {
            "margin-top": "10px",
            "margin-right": "20px"
          }
        }, [_c('span', {
          staticClass: "limit"
        }, [_vm._v("Использовано запросов:")]), _c('span', {
          staticClass: "limit"
        }, [_vm._v(" " + _vm._s(_vm.limitKwd.count) + " из " + _vm._s(_vm.limitKwd.limit) + " ")])]) : _vm._e(), _c('div', {
          staticClass: "report-settings"
        }, [_vm.calendar ? _c('range-calendar', {
          attrs: {
            "boundaries": _vm.calendarBoundaries
          },
          model: {
            value: _vm.calendar,
            callback: function ($$v) {
              _vm.calendar = $$v;
            },
            expression: "calendar"
          }
        }) : _vm._e(), _c('switch-input', {
          attrs: {
            "label": "FBS"
          },
          model: {
            value: _vm.fbs,
            callback: function ($$v) {
              _vm.fbs = $$v;
            },
            expression: "fbs"
          }
        })], 1)])];
      },
      proxy: true
    }], null, false, 3338359140)
  }, [this.$route.query.type === 'live' ? _c('span', [_vm._v(" Обзор показателей по поисковому запросу "), _c('br')]) : this.$route.query.type === 'url' ? _c('span', [_vm._v(" Обзор показателей по поисковому запросу c фильтрами "), _c('br')]) : _c('span', [_vm._v(" Обзор показателей по поисковому запросу ")]), _c('span', {
    staticClass: "blue-text"
  }, [_vm._v(_vm._s(_vm.$route.query.kwd || _vm.keyword && _vm.keyword.keyword))])]), _c('KeywordSearch', {
    attrs: {
      "type-id": this.$route.query.type,
      "search-value-default": this.$route.query.kwd || _vm.keyword && _vm.keyword.keyword,
      "limit": _vm.limitKwd
    }
  }), !this.$route.query.type || this.$route.query.type === 'std' ? _c('keyword-info', {
    staticClass: "mt32",
    attrs: {
      "keyword": _vm.keyword,
      "report-settings": _vm.reportSettings
    }
  }) : _vm._e(), _vm.reportSettings && !_vm.loading ? _c('entity-charts', {
    attrs: {
      "cells": _vm.cells,
      "loader-config": _vm.reportSettings,
      "loader": _vm.loadOverview,
      "period": _vm.calendar
    }
  }) : _vm._e(), _c('main-title', [this.$route.query.type === 'live' ? _c('span', [_vm._v(" Статистика продаж по поисковому запросу "), _c('br')]) : this.$route.query.type === 'url' ? _c('span', [_vm._v(" Статистика продаж по поисковому запросу c фильтрами "), _c('br')]) : _c('span', [_vm._v(" Статистика продаж по поисковому запросу "), _c('br')]), _c('span', {
    staticClass: "blue-text"
  }, [_vm._v(_vm._s(_vm.$route.query.kwd || _vm.keyword && _vm.keyword.keyword))])]), _c('app-tabs', {
    attrs: {
      "tabs": _vm.tabs_tables,
      "route-param": "tab"
    },
    model: {
      value: _vm.tabs_tables_model,
      callback: function ($$v) {
        _vm.tabs_tables_model = $$v;
      },
      expression: "tabs_tables_model"
    }
  }, [_c('data-table-actions', {
    attrs: {
      "id": _vm.getTableId()
    }
  })], 1), _c('div', {
    staticClass: "mt32"
  }, [_vm.reportSettings && _vm.tabs_tables_model && !_vm.loading ? _c(_vm.getTableComponent(_vm.tabs_tables_model.id), {
    key: _vm.tableKey,
    tag: "component",
    attrs: {
      "id": _vm.getTableId(),
      "report-settings": _vm.reportSettings,
      "track": _vm.track,
      "file-name": _vm.fileName,
      "type": 3
    }
  }) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };